import React, { useState } from 'react';
import ValidatePassword from './ValidatePassword';
import Translations from './Dictionary';

const PasswordInputValidation = ({password, setPassField,language}) => {
    // const [password, setPassword] = useState('');
    
    const onPassChange = (pass) => {
        //setPassword(pass)
        setPassField(pass)
    }

    return (
        <div className="mb-3">
        <label htmlFor="_register_password" className="form-label">
            <ul>
                <li>{Translations[language].PassValidationOne}</li>
                <li>{Translations[language].PassValidationTwo}</li>
                <li>{Translations[language].PassValidationThree}</li>
                <li>{Translations[language].PassValidationFour}</li>
                <li>{Translations[language].PassValidationFive}</li>
            </ul>
            {Translations[language].password}
        </label>
            <input
            type="password"
            className="form-control"
            id="_register_password"
            value={password}
            onChange={(e) => onPassChange(e.target.value)}
            />
            <ValidatePassword.ValidatePasswordMessage password={password}></ValidatePassword.ValidatePasswordMessage>
        </div>
        )
}

export default PasswordInputValidation;