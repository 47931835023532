// Loader.js
import React from 'react';
import './Loader.css'; // Import CSS file for styling
import LoadingImg from '../Assets/basic_loading.gif'

const Loader = ({ isLoading }) => {

  return (
    <div>
    {isLoading && <div className="loader-overlay">
      <div className="loader-container">
        <img src={LoadingImg} alt="Loading..." />
      </div>
    </div>}
    </div>
  );
};

export default Loader;
