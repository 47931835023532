import AuthHelper from "./components/AuthHelper";
import { ToastContainer, toast } from 'react-toastify';
import { showLoader, hideLoader } from './components/LoaderController';
import 'react-toastify/dist/ReactToastify.css';
const ApiCall = (url, method, body, onSuccess, onError, accessToken = '', TriggerCaptcha = null) => {
    //show Loading
    showLoader();

    // Default options for the Fetch API
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
        // Add any other headers if needed
      },
      // Include the body only for POST requests
      body: method === 'POST' ? JSON.stringify(body) : undefined,
    };
  
    // Make the Fetch API call
    fetch(url, options)
      .then(response => {
        //hide loading
        hideLoader();

        // Check if the response is successful (status code in the range 200-299)
        if (response.ok) {
          response.text().then(data => {
            if (data.startsWith("{") || data.startsWith("[")) {
              let response = JSON.parse(data);
              onSuccess(response)
            }
            else
            {
              onSuccess(data)
            }
            //console.log(data)
          })

        //   //console.log("ok")
        //   //console.log("response:", response)
        //   // Parse the JSON response
        //   return response.json().catch(e => {onSuccess({'miniFailure': true})
        // //console.log('mini failure')});
        } 
        else if (response.status === 400) {
          // If the response is not successful, throw an error
          ////console.log(response.json())

          //console.log(response)
          response.text().then(data => {
            if (data.startsWith("{") || data.startsWith("[")) {
              let response = JSON.parse(data);
              onError(response)
              //data.json().then
            }
            else
            {
              onError(data)
            }
            //console.log(data)
          })
          
          //throw new Error(`Error: ${response}`);
        }
        else if (response.status === 401)
        {
          TriggerCaptcha();
          ToasterError('Access Denied, If you are trying to login Please check your credentials. If you are logged in please Logout and login again')
        }
        else
        {
          ToasterError('something is not working right now, check everything is ok from your side and try again later. if the issue persists please contact support with the specific scenario that caused this error and we will address it')
        }
      })
      // .then(data => {
      //   // Call the onSuccess function with the parsed data
      //   //console.log(data)
      //   onSuccess(data);
      // })
      // .catch(error => {
      //   //console.log(error)
      //   //console.log(error['Error'])


        //error.json().then(res => //console.log(res))

        // Call the onError function with the error message
        //onError(error);
      //}
    //);
  };

  const TokenApiCall = async (url, method, body, onSuccess, onError, IsTokenRequired, TokenRedirect, SetUserData) => {
    if(IsTokenRequired)
    {
      let Token = await AuthHelper.GetAccessToken(TokenRedirect, SetUserData);
      ApiCall(url, method, body, onSuccess, onError, Token);
    }
    else
    {
      ApiCall(url, method, body, onSuccess, onError);
    }
    

  }

  const AsyncTokenApiCall = async (url, method, body, IsTokenRequired, TokenRedirect, SetUserData) => {
    if(IsTokenRequired)
    {
      let Token = await AuthHelper.GetAccessToken(TokenRedirect, SetUserData);
      let response = await AsyncApiCall(url, method, body, Token);
      return response
    }
    else
    {
      let response = await AsyncApiCall(url, method, body);
      return response
    }
    

  }

  const AsyncApiCall = async (url, method, body, accessToken = '') =>{
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
        // Add any other headers if needed
      },
      // Include the body only for POST requests
      body: method === 'POST' ? JSON.stringify(body) : undefined,
    };

    const returnObj = {"success": false, "data": {}};

    const response = await fetch(url, options);
    if (response.ok) {
      //console.log("ok")
      //console.log("response:", response)
      returnObj['success'] = true;
    } 

    

    const json = await response.json();
    //console.log(json);
    returnObj['data'] = json
    return returnObj;

  }

  const DefaultApiResult_NoAlerts = (Result) => {
    //console.log(Result)
  }

  const DefaultAPIResult = (Result) => {
    if (typeof Result === 'string')
    {
      ToasterError(Result)
      return;
    }
    const keys = Object.keys(Result);
    //console.log(keys)
    if (keys.includes('title'))
      {ToasterError(keys['title'])}
    else if (keys.includes('Title'))
      {ToasterError(keys['Title'])}
    else
    {
      ToasterError(Result[keys[0]])
    }
     

    //const keys = Object.keys(Result);
    ////console.log(Result.message)
    ////console.log(Result)
    //ToasterError('action failed please try again')
  }

  const ToasterError = (msg) => {
    toast.error(msg, {
      theme: "colored",
      autoClose:false
    });
  }

  const ToasterSuccess = (msg) => {
    toast.success(msg, {
      theme: "colored",
      autoClose:false
    });
  }

  const helperMethods = { ApiCall, AsyncApiCall, DefaultAPIResult, TokenApiCall, AsyncTokenApiCall, 
    DefaultApiResult_NoAlerts, ToasterError, ToasterSuccess };

export default helperMethods;
  
  
  