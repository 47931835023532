import React from 'react';

// Functional component example
const PageTitle = (props) => {
  return (
    <div>
        <div style={{width: '100%', backgroundColor:'rgb(181, 151, 90)', color:'black', padding:'0.25rem', marginBottom:'2rem'}} className='bg-light'>
            {/* Displaying the content passed between the tags */}
            <h3>{props.children}</h3>        
        </div>
    </div>
  );
};

export default PageTitle;
