// LanguageSwitch.js
import React from 'react';
import Translations from './Dictionary';
import LangIcon from '../Assets/352479_language_icon.svg'
import './LangSwitch.css'

const rtlLanguages = ["arabic"]

function setPageDirection(language) {
  const dir = rtlLanguages.includes(language) ? "rtl" : "ltr"
  document.documentElement.dir = dir
}


const LanguageSwitch = ({ changeLanguage, language }) => {
  // return (
  //   <button className="btn btn-primary" onClick={changeLanguage}>
  //     {language === 'english' ? 'switch to Arabic' : 'تغيير للانجليزية'}
  //   </button>
  // );
  //};
  //lang = changeLanguage(language)
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    changeLanguage(selectedLanguage);
    
    setPageDirection(selectedLanguage) // ltr
  };

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}> 
    
        

      
        <select
          id="languageSwitch"
          className="selectLang" //form-select 
          value={language}
          onChange={handleLanguageChange}
          style={{border: 'none', backgroundColor: '#00000000', cursor: 'pointer', fontSize: 'calc(0.8vw + 0.4rem)'}}
        >
          {Object.keys(Translations).map((key) => (
          <option value ={key} >
            {Translations[key][key]}
            <hr></hr>
          </option>
          ))}
          {/* <option value="english">English</option>
          <option value="arabic">عربي</option> */}
        </select>
        <div style={{marginTop: '.1rem', fontSize: 'calc(0.8vw + 0.6rem)'}}><i class="fas fa-globe"></i></div>
      </div>
    //</div>
  );
};



export default LanguageSwitch;




// // LanguageSwitch.js
// import React from 'react';
// import Translations from './Dictionary';
// import LangIcon from '../Assets/352479_language_icon.svg'

// const rtlLanguages = ["arabic"]

// function setPageDirection(language) {
//   const dir = rtlLanguages.includes(language) ? "rtl" : "ltr"
//   document.documentElement.dir = dir
// }


// const LanguageSwitch = ({ changeLanguage, language }) => {
//   // return (
//   //   <button className="btn btn-primary" onClick={changeLanguage}>
//   //     {language === 'english' ? 'switch to Arabic' : 'تغيير للانجليزية'}
//   //   </button>
//   // );
//   //};
//   //lang = changeLanguage(language)
//   const handleLanguageChange = (e) => {
//     const selectedLanguage = e.target.value;
//     changeLanguage(selectedLanguage);
    
//     setPageDirection(selectedLanguage) // ltr
//   };

//   return (
//     <div className="mb-3">
//       <div class="dropdown">
//         <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//           <h3 style={{fontStyle: 'italic'}}>&#127760;</h3>
//         </button>
//         <div class="dropdown-menu">
//           <a class="dropdown-item" href="#">Action</a>
//           <div class="dropdown-divider"></div>

//           {Object.keys(Translations).map((key) => (
//           <button className="dropdown-item" value ={key} onClick={handleLanguageChange}>
//             {Translations[key][key]}
//           </button>
//           ))}
//           <a class="dropdown-item" href="#">Action</a>
//           <a class="dropdown-item" href="#">Another action</a>
//           <a class="dropdown-item" href="#">Something else here</a>
//           <div class="dropdown-divider"></div>
//           <a class="dropdown-item" href="#">Separated link</a>
//         </div>
//       </div>
//       <label htmlFor="languageSwitch" className="form-label">
//         <h3 style={{fontStyle: 'italic'}}>&#127760;</h3>
//       {/* U+1F310 */}
//       {/* {Translations[language]['selectLanguage']} */}
//       {/* <img src={LangIcon} alt="Language Icon" /> */}
//       </label>
//       <i class="bi bi-globe"></i>
//       <select
//         id="languageSwitch"
//         className="form-select bi bi-globe"
//         // <i class="bi bi-globe"></i>
//         value={language}
//         onChange={handleLanguageChange}
//         style={{backgroundImage: <img src={LangIcon} alt="Language Icon" />}}
//       >
//         {Object.keys(Translations).map((key) => (
//         <option value ={key} >
//           {Translations[key][key]}
//         </option>
//         ))}
//         {/* <option value="english">English</option>
//         <option value="arabic">عربي</option> */}
//       </select>
//     </div>
//   );
// };



// export default LanguageSwitch;

