// LanguageSwitch.js
import React from 'react';
import AuthHelper from './AuthHelper';
import { Link } from 'react-router-dom';
import LanguageEncaps from './LanguageComponent_Encaps';
import MafLogo from '../Assets/Logo.png';//'../Assets/MafLogo.png';
import CarrefourLogo from '../Assets/carrefourLogo2.png';
//import FastQLogo from '../Assets/FastQ_Logo.svg';
// import LangIcon from '../Assets/352479_language_icon.svg'
import FastQLogo from '../Assets/FastQ_Logo-06.png';
import NavBarBG from '../Assets/Header_BG.jpg';
import './header.css'
import '../style_maf.css'






const Header = ({ redirectMethod, setUserData, setLanguage, userData}) => {
  
  const handleButtonclick = (e) => {
    AuthHelper.Logout(redirectMethod, setUserData);
  };

  const showProperEmail = (email) => {
    return email ? email.substring(0,14) + '..' : '';
  }

  return (
    <div>
 
      <nav className="navbar navbar-expand-lg navbar-light navbar-custom .BodyBG" style={{backgroundImage:{NavBarBG}, 
      boxSizing: 'border-box;'}}>

          <ul class="navbar-nav mr-auto" style={{width: '33%'}}>
            <li class="nav-item" style={{display:'flex', flexDirection:'column', justifyContent: 'flex-start'}}>
              <img src={MafLogo}  style={{minWidth: "125px", width: '35%'}}></img>
              <img src={CarrefourLogo} style={{minWidth: '75px', margin: '0.5rem', width: '21%'}}></img>                      
            </li>     
          </ul>

          <ul class="navbar-nav mx-auto" style={{justifyContent: 'center', width: '33%'}}>
            <li class="nav-item">
              <img src={FastQLogo} style={{minWidth: "100px", maxWidth: '300px', width: '60%', marginLeft:'20%', marginRight:'20%'}} ></img>
            </li>        
          </ul>

          <ul class="navbar-nav ms-auto" style={{width: '33%', justifyContent:'flex-end'}}>
            <li class="nav-item" style={{marginRight: '10px', marginLeft: '10px'}}>  
                <LanguageEncaps setGlobalLang={setLanguage}/>
    
                {localStorage.getItem('refresh-token') && 
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {userData['supplierNum'] && <div style={{padding:'0.3rem' , fontSize: 'calc(0.8vw + 0.4rem)'}}>No: {userData['supplierNum']}</div>}
                    <div style={{padding:'0.3rem' , fontSize: 'calc(0.8vw + 0.4rem)'}}>{showProperEmail(userData['name'])}</div>
                    <div style={{marginTop: '3rem;', cursor: 'pointer', fontSize: 'calc(0.8vw + 0.6rem)'}}><i className="fa fa-sign-out" aria-hidden="true" 
                    style={{marginTop: '0.7rem;'}} onClick={handleButtonclick}></i></div>
                  </div>
                }
            </li>
            
        </ul>
        </nav>

    </div>

  );
};

export default Header;



 /* <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <Link class="nav-link" to="/">Home</Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/login">login</Link>
            </li>
          </ul> */
          
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */



     /* <li class="nav-item p-3" style={{justifyContent: 'center', alignItems: 'center'}}>
            </li> */
            /* <li class="nav-item">
              <img src={FastQLogo}></img>
            </li> */
