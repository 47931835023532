import React, { useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';

import ReCAPTCHA from 'react-google-recaptcha';
import ReCaptchaCounter from './ReCaptchaCounter'; 



const RecaptchaProtection = forwardRef(({language, setOuterComponentVar}, ref) => {

    const [captchaToken, setCaptchaToken] = useState('');
    const [DisplayReCaptcha, setDisplayReCaptcha] = useState(false)
    const [DisplaySubmitCaptcha, setDisplaySubmitCaptcha] = useState(false)

    const TriggerCaptchaCount = () => {
        //console.log('Captcha submit button state: ', DisplaySubmitCaptcha)
        //console.log('Captcha Triggered')
        let res = ReCaptchaCounter(5,1)
        //console.log('captcha res: ', res)
        setDisplayReCaptcha(res);
        setOuterComponentVar(res);
        if(res == false)
        {
            setDisplaySubmitCaptcha(res)
        }


    }

    const handleCaptchaChange = (value) => {
        setCaptchaToken(value);
        if (value != null)
        {
          setDisplaySubmitCaptcha(true)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send the captchaToken along with your form data to the server
        if (captchaToken) {
            // If reCAPTCHA token exists, navigate to another page
            //console.log('reCAPTCHA token:', captchaToken);
            //nav(navPage);
            localStorage.setItem('CaptchCountArray', JSON.stringify([]));
            setDisplayReCaptcha(false)
            setDisplaySubmitCaptcha(false)
            setOuterComponentVar(false)

        } else {
            // reCAPTCHA not solved yet
            helperMethods.ToasterError('Please complete the reCAPTCHA challenge.');
        }
    };
    useImperativeHandle(ref, () => ({
        TriggerCaptchaCount
      }));

    return (
        <div>
        {DisplayReCaptcha ? (
            <div >
            <ReCAPTCHA style={{textAlign: 'center'}}
                sitekey="6Le8XfIpAAAAAEPirmS-nPZdy_VhOVssxvTSNPLr"
                onChange={handleCaptchaChange}
            />
            {DisplaySubmitCaptcha && (<button className='btn btn-primary' onClick={handleSubmit}>{Translations[language]['submit']}</button>)}
            </div>
        ):<div></div>}
        </div>
    )
    });

export default RecaptchaProtection;
