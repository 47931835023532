const ReCaptchaCounter = (reloadCount, refreshPeriodInHours) => {
    // Function to calculate the current date and time
    const getCurrentDateTime = () => {
      return new Date().getTime();
    };

    // Function to retrieve the reload count array from localStorage
    const getReloadCountArrayFromLocalStorage = () => {
      const reloadCountArrayJSON = localStorage.getItem('CaptchCountArray');
      return reloadCountArrayJSON ? JSON.parse(reloadCountArrayJSON) : [];
    };

    // Function to update the reload count array and save it to localStorage
    const updateReloadCountArrayInLocalStorage = (newReloadCountArray) => {
      localStorage.setItem('CaptchCountArray', JSON.stringify(newReloadCountArray));
    };

    // Function to remove reload records older than specified refresh period
    const removeOldReloadRecords = (reloadCountArray, currentTime, refreshPeriodInMs) => {
      const thresholdTime = currentTime - refreshPeriodInMs;
      return reloadCountArray.filter((record) => record.reloadDate >= thresholdTime);
    };

    // Function to add a new reload record to the array
    const addNewReloadRecord = (reloadCountArray, currentTime) => {
      reloadCountArray.push({ reloadDate: currentTime });
      return reloadCountArray;
    };

    // Main logic
    const reloadCountArray = getReloadCountArrayFromLocalStorage();
    const currentTime = getCurrentDateTime();
    const refreshPeriodInMs = refreshPeriodInHours * 60 * 60 * 1000;

    const updatedReloadCountArray = removeOldReloadRecords(reloadCountArray, currentTime, refreshPeriodInMs);
    const newReloadCountArray = addNewReloadRecord(updatedReloadCountArray, currentTime);

    updateReloadCountArrayInLocalStorage(newReloadCountArray);

    // Check if reloadCount exceeds the specified limit
    
    const reloadCountExceeded = reloadCount && newReloadCountArray.length > reloadCount;
    return reloadCountExceeded


}

export default ReCaptchaCounter;
