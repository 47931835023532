// Form.js
import React, { useState, useEffect } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import LanguageObject from './languageObject'


const FormSubmitted = ({navigate, returnPage, SubmissionResult}) => {
  const [language, setLanguage] = useState(LanguageObject['Lang']);

  function isValidDateFormat(dateString) {
    // Regular expression to match the format "YYYY-MM-DD HH:MM:SSZ"
    var regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}Z$/;
    return regex.test(dateString);
  }

  function isTomorrow(dateTimeString) {
    // Convert the input date-time string to a Date object
    var inputDate = new Date(dateTimeString);

    var tomorrow = new Date();
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
    tomorrow.setHours(0, 0, 0, 0); // Set time to midnight for comparison

    // Compare inputDate with tomorrow's date
    return inputDate.toDateString() === tomorrow.toDateString();
  }

  const fixTime = (val) => {
    if(isValidDateFormat(val))
    {
      let date = new Date(val);
      if(isTomorrow(val))
      {
        return 'Tomorrow ' + date.toLocaleTimeString() 
      };
      return date.toLocaleTimeString()
    }
    else
    {
      return val
    }
  }
  

  return (
    <div style={{textAlign: 'center'}}>
       
        <h4>Congratulations!! You have successfully submitted your request</h4>
        <div className='border m-5 flex'>
            <br></br>
            {Object.entries(SubmissionResult).map(([key, val]) => (
                <span>
                    {/* <label><h2><strong>{Translations[language][key]}:</strong></h2></label>
                    <div><h2>{Translations[language][val]}:</h2></div> */}
                    <h5 style={{color: 'gray'}}><strong>{key}:</strong> {fixTime(val)}</h5>            
                    <br></br>
                </span>
            ))}
        </div>
        <button className="btn btn-primary" onClick={(e) => {navigate(returnPage)}}><h5>Return to Previous Page</h5></button>  
    </div>
  );
};

export default FormSubmitted;
