//EmailConfirmed
import React, { useState, useEffect } from 'react';
import LanguageSwitch from './LanguageSwitch';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import LanguageObject from './languageObject'
import AuthHelper from './AuthHelper';



const EmailUnconfirmedPage = ({ConfirmEmailData, navigate, setConfirmEmailData, setUserData, language, TriggerCaptcha}) => {
  // const [language, setLanguage] = useState(LanguageObject['Lang']);
  


//   useEffect(() => {
//     if(userData['role'] == 'Supplier')
//     {
//       // Fetch countries from an API
//       helperMethods.TokenApiCall(configs["BaseURL"]+configs["GetCountries"], 'GET', 
//       undefined, setCountries, helperMethods.DefaultAPIResult, true, navigate, setUserData)
//     }
//     else if(Object.keys(userData).length === 0)
//     {
//       navigate('/');
//     }
//     else {
//       navigate('/login');
//     } 
//   }, []);

  

  const handleResendConfirmEmail = () => {
    const submissionData = {"email": ConfirmEmailData['email']}
    //console.log('email',ConfirmEmailData)
    AuthHelper.ResendConfirmationEmail(submissionData, navigate, setUserData);
  };



  const handleLoginRedirect = () => {
    //navigate('/login')
    AuthHelper.Logout(navigate, setUserData);
  };

  return (
    <div class="mx-auto container mt-4"> 
    <div className="border p-4">
        <div class="mb-3">
            <h1>Please Confirm Your email First</h1>
        </div>
        <button onClick={handleLoginRedirect} className="btn btn-primary">
            Return To Login
        </button>
        <span className='p-2'></span>
        <button onClick={handleResendConfirmEmail} className="btn btn-primary">
            Resend confirmation email
        </button>
    </div>
    </div>
  );
};

export default EmailUnconfirmedPage;
