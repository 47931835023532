import React, { useState } from 'react';
import LanguageSwitch from './LanguageSwitch';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import LanguageObject from './languageObject';
import { jwtDecode } from "jwt-decode";
import AuthHelper from './AuthHelper';
import { Link } from 'react-router-dom';
import PanelTitle from './PanelTitle';

const ForgotPassword = ({navigate, setUserData, setConfirmEmailData, language, TriggerCaptcha}) => {
  const [email, setEmail] = useState('');


  
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
  //     toast.error('Email is required', {
  //   theme: "colored",
  //   autoClose:false
  // });
      helperMethods.ToasterError('Email is required')
      return;
    }
    // else{
    //   helperMethods.ToasterSuccess('if this email exists Please check your mail and Reset your password')
    // }

    const submissionData = {
      "email":email
    } 

    TriggerCaptcha();

    helperMethods.ApiCall(configs["BaseURL"]+configs["CheckConfirmationMail"] + '?email='+email, 'GET', 
    undefined, handleCheckConfirmation, helperMethods.DefaultAPIResult)

    
  };

  const handleCheckConfirmation = (response) =>{
    const submissionData = {
      "email":email
    } 
    if(response['Status'] == 0)
    {
      AuthHelper.ForgotPassword(submissionData, navigate, setUserData);
    }
    else if (response['Status'] == 1)
    {
      helperMethods.ToasterSuccess('Please Confirm Your Email First')
      setConfirmEmailData({'email':email});
      navigate('/ConfirmEmail')
    }
    else if (response['Status'] == 2)
    {
      helperMethods.ToasterSuccess('if this email exists Please check your mail and Reset your password')
      navigate('/login') 
    }
  }

  return (
    <div className="container mt-4">
      <form onSubmit={handleForgotPassword} className="border p-4">
        <PanelTitle>{Translations[language].ResetPassword}</PanelTitle>
        <div className="mb-3">
          <label htmlFor="_forgot_email" className="form-label">
          {Translations[language].EnterMail}   
          </label>
          <input
            type="email"
            className="form-control"
            id="_forgot_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
        {Translations[language].submit}        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
