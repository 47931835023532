// Form.js
import React, { useState, useEffect } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import EmailUnconfirmed from './EmailNotConfirmed';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import PageTitle from './PageTitle';
import PanelTitle from './PanelTitle';
import { PhoneNumberUtil } from 'google-libphonenumber';


const Form = ({userData, navigate, setUserData, setReturnPage, setResultObj, language}) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactMethod, setContactMethod] = useState('2');
  const [numberOfCars, setnumberOfCars] = useState('0');
  const [numberOfPalettes, setnumberOfPalettes] = useState('0');
  const [carDropdownValue, setCarDropdownValue] = useState('');
  const [carDropdownOptions, setCarDropdownOptions] = useState([]);
  const [branch, setBranch] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [branches, setBranches] = useState([]);
  const [supplierNumber, setSupplierNumber] = useState('');
  const [bookingDay, setBookingDay] = useState('2');
  const [EmailConfirmed, setEmailConfirmed] = useState(userData['emailConfirmed']);
  const [ShowTodayRadio, setShowTodayRadio] = useState(true)
  const [Orders , setOrders] = useState([]);
  const [RedFlag, setRedFlag] = useState(false);

  const OnSuccessAPI = (data) => {
    //console.log(data)
    var message = 'Booking code: '+ data['Code'] + ', your turn: ' + data['WaitingInLine'] + ', waiting time: ' + data['PreferredArrivaltime'];
    setResultObj(data);
    setReturnPage('/scheduled-orders');
    navigate('/FormSubmitted');
  };

  const OnFailedAPI = (data) => {
    //console.log(data)
    var message = 'Failed: '+ data['error'];
    
  };


  useEffect(() => {
    if(userData['role'] == 'Supplier')
    {
      // Fetch countries from an API
      helperMethods.TokenApiCall(configs["BaseURL"]+configs["GetCountries"], 'GET', 
      undefined, setCountries, helperMethods.DefaultApiResult_NoAlerts, true, navigate, setUserData)
    }
    else if(Object.keys(userData).length === 0)
    {
      navigate('/');
    }
    else {
      navigate('/login');
    }
    //console.log(userData)

    
    
  }, []);

  useEffect(() => {
      //console.log(userData)
      // Fetch branches based on the selected country
      helperMethods.TokenApiCall(configs["BaseURL"]+configs["GetCountryBranches"] + "?CountryID=" + selectedCountry, 'GET', 
      undefined, setBranches, helperMethods.DefaultApiResult_NoAlerts, true, navigate, setUserData)

      helperMethods.TokenApiCall(configs["BaseURL"]+configs["GetOrderTransporters"] + "?CountryID=" + selectedCountry, 'GET', 
      undefined, setCarDropdownOptions, helperMethods.DefaultApiResult_NoAlerts, true, navigate, setUserData)
    
  }, [selectedCountry]);

  useEffect(() => {
    //console.log(userData)
    // Fetch branches based on the selected country
    if (branch != 0) {
      helperMethods.TokenApiCall(configs["BaseURL"] + configs["CheckBranchLastReservation"] + "?branchID=" + branch, 'GET',
        undefined, handleLastReservation, helperMethods.DefaultApiResult_NoAlerts, true, navigate, setUserData)
    }
    //helperMethods.TokenApiCall(configs["BaseURL"]+configs["CheckBranchLastReservation"] + "?branchID=" + branch, 'GET', 
    //undefined, handleLastReservation, helperMethods.DefaultApiResult_NoAlerts, true, navigate, setUserData)

  
}, [branch]);


  const handleLastReservation = (data) => {
    if(data['ValidYN'] == false)
    {
      setShowTodayRadio(false);
      setBookingDay('2');
    }
    else
    {
      setShowTodayRadio(true);
    }
  }


  const handleRadioChange = (e) => {
    //console.log('radio change')
    //console.log(e)
    //console.log(e.target.value)
    setBookingDay(e.target.value)
    //console.log(bookingDay)
  }

  //var RedFlag = false;
  const OrderData = {
    'OrderNumber':orderNumber,
    'BranchId':branch,
    "BookingDay": bookingDay,
    "Statue":'2'
  }

  const CheckOrder =async () => {
    setRedFlag(true);
    const OnCheckSuccessAPI =(data) =>{
       helperMethods.ToasterSuccess(data.success)
      // helperMethods.ToasterError(data.error)
      // console.log(data); // This will output either true or false
      setOrders([...Orders, orderNumber]);
      setOrderNumber("")
    } 
    const OnCheckfailAPI =(data) =>{
      // helperMethods.ToasterSuccess(data.success)
       helperMethods.ToasterError(data.error)
       console.log(data); // This will output either true or false
    } 
    helperMethods.TokenApiCall(configs["BaseURL"]+ configs["CheckOrder"], 'POST', OrderData, OnCheckSuccessAPI, 
      OnCheckfailAPI, true, setUserData)
      
  }
  var removeOrder =(index)=>{
    const newOrders = [...Orders];
    newOrders.splice(index, 1);
    setOrders(newOrders); 
    return
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (RedFlag) {
      setRedFlag(false)
      return
    }
    if(numberOfPalettes == '0' || numberOfCars == '0' || parseInt(numberOfPalettes) > 99 || parseInt(numberOfCars) > 50)
    {
      helperMethods.ToasterError(Translations[language].TransportationReason)
      return
    }
    else if (!orderNumber && !OrderData)
    {
        helperMethods.ToasterError(Translations[language].OrderReason)
        return
    }
    else if(!branch || branch == 0)
    {
        helperMethods.ToasterError(Translations[language].BranchReason)
        return
    }
    else if(!carDropdownValue || carDropdownValue == 0)
    {
      helperMethods.ToasterError(Translations[language].TransTypeReason)
      return
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      let valid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
      ////console.log("phone number"+valid);
      if(!valid)
      {
        helperMethods.ToasterError(Translations[language].PhoneReason);
        return
      }
    } catch (error) {
      helperMethods.ToasterError(Translations[language].PhoneReason);
    return;
    }

    if (contactMethod == '1')
    {
      helperMethods.ToasterError('are you sure the Driver has whatsapp and is connected to the internet ?')
    }
    // Handle form submission logic here
    
    var submissionData = {
      "TruckNumber":numberOfCars,
      "OrderTransporterType":carDropdownValue,
      "Branch":branch,
      "PaletteNumber":numberOfPalettes,
      "PreferedContactMethod":contactMethod,
      "DeliveryPhoneNumber":phoneNumber,
      "OrderNumber":Orders.toString(),
      "SupplierNumber": userData['supplierNum'],
      "BookingDay": bookingDay,
      "AuthStatus":'2'
    }
    if(orderNumber){
      submissionData["OrderNumber"] = orderNumber
   }
    helperMethods.TokenApiCall(configs["BaseURL"]+configs["BookDelivery"], 'POST', submissionData, OnSuccessAPI, 
    helperMethods.DefaultAPIResult, true, navigate, setUserData)

    
  };

  return (
    <div>
      {(EmailConfirmed != "True") && <EmailUnconfirmed userData={userData} navigate={navigate} setUserData={setUserData}/>}
      {(EmailConfirmed === "True") && 
    <div class="mx-auto mt-4"> 
    <PageTitle>{Translations[language].bookOrder}</PageTitle>

    <form onSubmit={handleSubmit} className="border p-4">
      <PanelTitle>{Translations[language].orders}</PanelTitle>
      <div className="mb-3">
        <label htmlFor="countrydropdown" className="form-label">
          {Translations[language].country}
        </label>
        <select
          className="form-select"
          id="countrydropdown"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {Object.keys(countries).map((option, index) => (
            <option key={index} value={option}>
              {countries[option]}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="branchdropdown" className="form-label">
          {Translations[language].branch}
        </label>
        <select
          className="form-select"
          id="branchdropdown"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
        >
          {Object.keys(branches).map((option, index) => (
            <option key={index} value={option}>
              {branches[option]}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="ReservationDateRadios" id="TomorrowRadio" value="2" 
          checked={bookingDay == '2'}
          onChange={(e) => handleRadioChange(e)}/>
          <label className="form-check-label" for="TomorrowRadio">
            {Translations[language].TomorrowReserveDate}
          </label>
        </div>
        {ShowTodayRadio &&
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="ReservationDateRadios" id="TodayRadio" value="1"
          //checked={bookingDay}
          onChange={(e) => handleRadioChange(e)}/>
          <label className="form-check-label" for="TodayRadio">
            {Translations[language].TodayReserveDate}
          </label>
        </div>}
      </div>
      <div class="mb-3">
        <label htmlFor="ordernumber" class="form-label">
          {Translations[language].orderNumber}
        </label>
              <div className='row mb-2'>
                <div className='col-11'>
                  <input
                    type="text"
                    className="form-control"
                    id="ordernumber"
                    aria-describedby="ordernumberhelp"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)} />
                </div>
                <div className='col-1'>
                  <button className='btn btn-primary p-2 ' onClick={CheckOrder}><i class="fa-solid fa-plus"  ></i> Add Order</button>
                </div>
              </div>
                {Orders.map((order, index) => (
                  <div key={index} className='d-inline m-2 p-1 rounded' style={{background:"#E0E0E0	"	}}>
                    <span className='d-inline mr-2'>{order}</span> &nbsp;	
                    <i className="fa-solid fa-xmark" style={{ cursor: 'pointer' }} onClick={() => removeOrder(index)}></i>
                  </div>
                ))}
      </div>

      

      

    
      
      
      <div className="mb-3">
          <label htmlFor="phonenumber" className="form-label">
            {Translations[language].phoneNumber}
          </label>
          <PhoneInput
            className="form-control"
            style={{display:'flex'}}
            defaultCountry="eg"
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
          />
          
      </div>
      <div className="mb-3">
          <label htmlFor="contactmethod" className="form-label">
            {Translations[language].contactMethod}
          </label>
          <select
            className="form-select"
            id="contactmethod"
            value={contactMethod}
            onChange={(e) => setContactMethod(e.target.value)}
          >
            {/* <option value="1">{Translations[language].whatsapp}</option> */}
            <option value="2">{Translations[language].sms}</option>
          </select>
        </div>

        
        <div className="mb-3">
          <label htmlFor="cardropdown" className="form-label">
            {Translations[language].deliveryMethodCarType}
          </label>
          <select
            className="form-select"
            id="cardropdown"
            value={carDropdownValue}
            onChange={(e) => setCarDropdownValue(e.target.value)}
          >
            {Object.keys(carDropdownOptions).map((option, index) => (
              <option key={index} value={option}>
                {carDropdownOptions[option]}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="numberofcars" className="form-label">
            {Translations[language].numberOfCars}
          </label>
          <input
            type="number"
            min="0"
            max="50"
            className="form-control"
            id="numberofcars"
            value={numberOfCars}
            onChange={(e) => setnumberOfCars(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="numberofplates" className="form-label">
            {Translations[language].numberOfPalettes}
          </label>
          <input
            type="number"
            min="0"
            max="99"
            className="form-control"
            id="numberofplates"
            value={numberOfPalettes}
            onChange={(e) => setnumberOfPalettes(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          {Translations[language].submit}
        </button>

    </form>
    </div>}
    </div>
  );
};

export default Form;

{/* <CustomModal isActive={modalActive} showSave={false} messageBody={modalMessageBody} 
      messageTitle={'check in success'} closeModal={onModalClose} onSave={onModalClose} /> */}
    {/* style={{width:'300px', border: '1px solid grey', padding: '20px'}}> */}

    {/* <div className="mb-3">
          <label htmlFor="cardropdown" className="form-label">
            {Translations[language].deliveryMethodCarType}
          </label>
          <select
            className="form-select"
            id="cardropdown"
            value={carDropdownValue}
            onChange={(e) => setCarDropdownValue(e.target.value)}
          >
            {carDropdownOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div> */}

          {/* <div className="mb-3">
          <label htmlFor="branch" className="form-label">
            {Translations[language].branch}
          </label>
          <input
            type="text"
            className="form-control"
            id="branch"
            aria-describedby="branchhelp"
            value={branch}
            onChange={(e) => setBranch(e.target.value)}
          />
        </div> */}
{/* <input
            type="tel"
            className="form-control"
            id="phonenumber"
            aria-describedby="phonenumberHelp"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          /> */}

    {/* <div class="mb-3">
        <label htmlFor="suppliernumber" class="form-label">
          {Translations[language].supplierNumber}
        </label>
        <input 
        type="text" 
        className="form-control" 
        id="suppliernumber" 
        aria-describedby="ordernumberhelp"
        value={supplierNumber}
        onChange={(e) => setSupplierNumber(e.target.value)}/>
      </div> */}
