import './ReceptionNew.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState, useEffect } from 'react';

const Table = ({ headers, tableItems, ButtonMethod, TableTitle = ''}) =>
{ 
    return(
        <div style={{width: '100%'}} className='border'>
        <div style={{backgroundColor: 'rgb(181, 151, 90)', color:'white'}} className='p-2'>
            <strong>{TableTitle != '' ? TableTitle + ' - ': ''}{tableItems.length} Entries:</strong></div>
        <div style={{overflowX: 'auto'}}>
        <table class="table table-striped table-responsive p-0 m-0" >
        <thead>
            <tr>
                {headers.map(key => (
                    <th key={key} scope="col">{key}</th>
                    ))}      
                {/* <th>Actions</th>      */}
            </tr>
        </thead>
        <tbody>           
            {tableItems.map((obj, index) => (
                <tr key={index}>
                    {Object.entries(obj).map(([key, value]) => (
                        <td key={key+index}>{value}</td>
                    ))}
                    {/* <td><button class="btn btn-primary" onClick={(e) => ButtonMethod(index, e)}>&#8594;</button></td> */}
                </tr>
            ))}
        </tbody>
        </table></div>
    </div>
    )
}
export default Table;