import helperMethods from "../HelperMethods";
import LanguageSwitch from './LanguageSwitch';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import configs from './Configs.json';
import LanguageObject from './languageObject';
import { jwtDecode } from "jwt-decode";
import { BrowserRouter as Router, Route, Link, Routes, useNavigate, redirectDocument  } from 'react-router-dom';

const clearUserData = (setUserData) =>{
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
  localStorage.removeItem('access-expiry');
  setUserData({})
}
const Logout =(redirectMethod, setUserData) => {
  clearUserData(setUserData);
  redirectMethod('/login');
}
const Login = (submissionData, redirectMethod, setUserData, TriggerCaptcha) =>{
  //const navigate = useNavigate();
  let MethodsObj ={
    SetUser: function(input) {},
    Redirect: function(input) {},
    TriggerCaptcha: function(input) {}
  }

  MethodsObj.Redirect = redirectMethod;
  MethodsObj.SetUser = setUserData;
  MethodsObj.TriggerCaptcha = TriggerCaptcha;


  const OnSuccessLogin = (data, Methods=MethodsObj) => {
   
    localStorage.setItem('access-token', data['token']);
    localStorage.setItem('refresh-token', data['refreshToken']);
    localStorage.setItem('access-expiry', data['expiresAt']);   
    Methods.Redirect('/');
    
  };

  const OnFailedLogin = (data, Methods=MethodsObj) => {
    //console.log('Failed Login Called')
    Methods.TriggerCaptcha();
    helperMethods.ToasterError('User Name/Email or Password is not correct')
    
  };
  helperMethods.ApiCall(configs["BaseURL"]+configs["Login"], 'POST', submissionData, 
  OnSuccessLogin, OnFailedLogin, '', TriggerCaptcha);
  
}

const GetUserData = async (redirectMethod, setUserData) => {
  let token = await GetAccessToken(redirectMethod, setUserData);
  if(token)
  {
    return GetUserDataValidToken(token, localStorage.getItem('access-expiry'));
  }
  else{
    return {}
  }
}

const GetAccessToken = async (redirectMethod, setUserData) => {
  let stored_access_token = localStorage.getItem('access-token');
  let stored_refresh_token = localStorage.getItem('refresh-token');
  let date = new Date(localStorage.getItem('access-expiry'));
  
  if(date.getTime() - Date.now() < 15000)
  {
    const submissionData = {
      "token":stored_access_token,
      "refreshToken":stored_refresh_token
    }

    var response = await helperMethods.AsyncApiCall(configs["BaseURL"]+configs["RefreshToken"] ,'POST', submissionData)
    if(response['success'])
    {
      localStorage.setItem('access-token', response['data']['token']);
      localStorage.setItem('refresh-token', response['data']['refreshToken']);
      localStorage.setItem('access-expiry', response['data']['expiresAt']);
      stored_access_token = response['data']['token'];
    }
    else
    {
      Logout(redirectMethod, setUserData)
    }
  }

  //window.location.href = '/login'
  return stored_access_token;
}

const ForgotPassword = (submissionData, redirectMethod, setUserData) => {
  let MethodsObj ={
    SetUser: function(input) {},
    Redirect: function(input) {},
    clearUserData: function(input) {},
  }

  MethodsObj.Redirect = redirectMethod;
  MethodsObj.SetUser = setUserData;
  MethodsObj.clearUserData = clearUserData;

  const OnSuccessAPI = (data, Methods=MethodsObj) => {
    helperMethods.ToasterSuccess('an email has been sent to you please use reset link to reset your password');
    MethodsObj.clearUserData(MethodsObj.SetUser);
    Methods.Redirect('/login')
  };    

  // const OnSuccessAPI = (data) => {
  //   if(data['miniFailure'] === true)
  //   {
  //      //OnSuccessApi is called twice due to response failure (.catch)
  //     //console.log('alert confirmation')
  //     helperMethods.ToasterError('an email has been sent to you please use reset link to reset your password or copy reset code');
  //     //Logout(redirectMethod, setUserData);
  //   }
  //   else
  //   {
  //     return
  //   } 
  // };
  //console.log('forgot password not called yet')
    
  helperMethods.ApiCall(configs["BaseURL"]+configs["ForgotPassowrd"], 'POST', submissionData, OnSuccessAPI, 
  helperMethods.DefaultAPIResult);
};

const ResetPassword = (submissionData, redirectMethod, setUserData) => {
  // let MethodsObj ={
  //   SetUser: function(input) {},
  //   Redirect: function(input) {},
  //   Logout: function(input, input2) {},
  // }

  // MethodsObj.Redirect = redirectMethod;
  // MethodsObj.SetUser = setUserData;
  // MethodsObj.Logout = Logout;

  // const OnSuccessAPI = (data, Methods=MethodsObj) => {
  //   helperMethods.ToasterError('your password is reset please login again with the new password');
  //   MethodsObj.Logout(redirectMethod, setUserData);
  // };    

  const OnSuccessAPI = (data) => {
      //console.log('alert confirmation')
      helperMethods.ToasterError('your password is reset please login again with the new password')
      Logout(redirectMethod, setUserData);
  };
  //console.log('reset password not called yet')
    
  helperMethods.ApiCall(configs["BaseURL"]+configs["ResetPassword"], 'POST', submissionData, OnSuccessAPI, 
  helperMethods.DefaultAPIResult);
};


const ResendConfirmationEmail = (submissionData, redirectMethod, setUserData) => {
  const OnSuccessAPI = (data) => {
    
       //OnSuccessApi is called twice due to response failure (.catch)
      //console.log('alert confirmation')
      helperMethods.ToasterSuccess('an email has been sent to you please confirm your email')
      Logout(redirectMethod, setUserData);
   
  };
  //console.log('resend confirmation not called yet')

  helperMethods.ApiCall(configs["BaseURL"]+configs["ResendConfirmationEmail"], 'POST', submissionData, OnSuccessAPI, 
  helperMethods.DefaultAPIResult);
};

const GetUserDataValidToken = (token, time) =>{
  const decoded = jwtDecode(token);

  const date = new Date(time);
  let user ={}
  if(date.getTime() > Date.now())
  {
    user['name'] = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
    user['role'] = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    user['email'] = decoded['email']
    user['countryID'] = decoded['countryID']
    user['emailConfirmed'] =  decoded['emailConfirmed']
    if (user['role'] == 'Supplier')
    {
      user['supplierNum'] = decoded['supplierNum']
    }
    else
    {
      user['branchID'] = decoded['branchID']
      user['branchName'] = decoded['branchName']
    }
  }
  return user
  
  
}

export default { Login, GetAccessToken, GetUserData, Logout, ResendConfirmationEmail, ForgotPassword, ResetPassword };
  
  
  