// Dictionary.js
import React from 'react';

const Translations = {
  english: {
    english: 'English',
    orderNumber: 'Order Number',
    phoneNumber: 'Phone Number',
    contactMethod: 'Contact Method',
    numberOfCars:'Number of Vechiles',
    numberOfPalettes: 'Number of Palettes',
    whatsapp: 'WhatsApp',
    sms: 'SMS',
    submit: 'Submit',
    cageNumber: 'Cage Number',
    cageName: 'Cage Name',
    branch: 'Branch',
    paletteCapacity: 'Palette Capacity',
    isFreshCage: 'Is Fresh Cage',
    cageWidth: 'Cage Width',
    cageLength: 'Cage Length',
    cageHeight: 'Cage Height',
    selectLanguage: 'Select Language',
    deliveryMethodCarType: 'Delivery Method-Vechile Type',
    reservationCode: 'Reservation Code',
    country: 'Country',
    supplierNumber: 'Supplier Number',
    TomorrowReserveDate: 'Reserve Tomorrow',
    TodayReserveDate: 'Reserve Today',
    email: 'email',
    emailUserName: 'email - domain\\username',
    password:'password',
    SecurityTitle: 'Check-in Supplier:',
    SecurityPanel: 'Check-in Order',
    WaitingTime: "It's estimated you will enter at: ",
    Time: 'Time',
    IncorrectCode: 'Incorrect Code',
    bookOrder: 'Book Order',
    orders: 'Orders',
    scheduledOrders: 'Scheduled Orders',
    currentlyBookedOrders: 'Currently Booked Orders:',
    bookNewOrder: 'Book a new Order',
    EnterMail:'Enter your email address:',
    ResetPassword:'Reset Password - Submit Your Account Email',
    Login:'Login',
    ForgetPassword:'Forgot Password?',
    Register:'new user? register',
    Screen:'Screen',
    CancelOrder :'Cancel Order',
    TransportationReason:'Number of Cars and/or Number of Palettes cannot be 0 or very big',
    OrderReason:'Please enter Order number',
    BranchReason:'Please select Branch',
    TransTypeReason:'please select Delivery method',
    PhoneReason:'Please Enter valid Phone number',
    ReservationReason:'Please enter Reservation Code',
    TaxId:'Tax ID',
    PassValidationOne:'Passwords must be at least 6 characters',
    PassValidationTwo:'Passwords must have at least one lowercase ( a - z )',
    PassValidationThree:'Passwords must have at least one uppercase ( A - Z )',
    PassValidationFour:'Passwords must have at least one digit ( 0 - 9 )',
    PassValidationFive:'Passwords must have at least one non alphanumeric character',
    Register1:'register',
    ResetPass:'Reset Password',
    resendResetLink:'resend reset link',
    BookedOrders:'booked orders',
    CancelOrder:'Are You Sure you want to cancel this order ?',
    MakeConfirmation:'Make Confirmation',
    Close:'Close',
    Receiving:'receiving',
    Entities:'Entities',
    FilterOrderBy : 'Filter Order By :',
    State :'State',
    TransportationType :'Transportation Type',
    OrderType : 'Order Type',
    Cages : 'Cages',
    Current :'Current',
    Truck:'Truck',
    Started : 'Started',
    Cancel:'Cancel'


    // Add more Translations as needed
  },
  arabic: {
    arabic: 'عربي',
    orderNumber: 'رقم الطلب',
    phoneNumber: 'رقم الهاتف',
    contactMethod: 'طريقة الاتصال',
    numberOfCars: 'عدد المركبات ',
    numberOfPalettes: 'عدد الباليتات',
    whatsapp: 'واتساب',
    sms: 'رسالة نصية',
    submit: 'تأكيد',
    cageNumber: 'رقم القفص',
    cageName: 'اسم القفص',
    branch: 'الفرع',
    paletteCapacity: 'سعة البليت',
    isFreshCage: 'قفص طازج',
    cageWidth: 'عرض القفص',
    cageLength: 'طول القفص',
    cageHeight: 'ارتفاع القفص',
    selectLanguage: 'اختر اللغة',
    deliveryMethodCarType: 'طريقة التوصيل - نوع المركبة',
    reservationCode: 'كود الحجز',
    country: 'البلد',
    supplierNumber: 'رقم المورد',
    TomorrowReserveDate: 'حجز للغد',
    TodayReserveDate: 'حجز لليوم',
    email: 'البريد الإلكتروني',
    emailUserName: "البريد الالكتروني - نطاق\\اسم المستخدم",
    password:'كلمة المرور',
    SecurityTitle: 'تسجيل دخول المورد:',
    SecurityPanel: 'تسجيل دخول الطلب',
    WaitingTime: 'متوقع دخولك الساعة: ',
    Time: 'الوقت',
    IncorrectCode: 'الرمز خاطئ',
    bookOrder: 'تسجيل الطلب',
    orders: 'الطلبات',
    scheduledOrders: 'الطلبات المسجلة',
    currentlyBookedOrders: 'الطلبات المسجلة حاليا:',
    bookNewOrder: 'سجل طلب جديد',
    EnterMail:'ادخل الايميل الخاص بك :',
    ResetPassword:'اعاده تعيين كلمه السر - ارسل الايميل الخاص بك ',
    Login:'تسجيل دخول',
    ForgetPassword:'نسيت كلمه السر',
    Register:'تسجيل ؟ مستخم جديد',
    Screen:'الشاشه',
    CancelOrder :'الغاء الطلب',
    TransportationReason:'لا يمكن ان يكون عدد المركبات او الباليتات يساوي صفر او عدد كبير جدا ',
    OrderReason:'برجاء ادخال رقم الاوردر',
    BranchReason:'برجاء ادخال الفرع',
    TransTypeReason:'برجاء اخيار وسيله توصيل',
    PhoneReason:'برجاء ادخال رقم هاتف صحيح',
    ReservationReason:'برجاء ادخال كود الحجز',
    TaxId:'رقم البطاقه الضريبيه',
    PassValidationOne:'كلمه السري يجب ان يحتوي علي الاقل علي 6 احرف ',
    PassValidationTwo:'كلمه السري يجب ان يحتوي علي الاقل علي حرف صغير ( a - z )',
    PassValidationThree:'كلمه السري يجب ان يحتوي علي الاقل علي حرف كبير ( A - Z )',
    PassValidationFour:'كلمه السري يجب ان يحتوي علي الاقل علي رقم ',
    PassValidationFive:'كلمه السري يجب ان يحتوي علي الاقل علي حرف مميز ( ! - % )',
    Register1:'تسجيل',
    ResetPass:'اعاده تعيين كلمه السر',
    resendResetLink:'اعاده ارسال لينك التفعيل',
    BookedOrders:'الاوردرات المحجوزه',
    CancelOrder:'هل متأكد من الغاء الاوردر؟',
    MakeConfirmation:'تأكيد الالغاء',
    Close:'الغاء',
    Receiving:'الاستلام',
    Entities:'المدخلات',
    FilterOrderBy : 'ترتيب الاوردر حسب :',
    State :'الحاله',
    TransportationType :'نوع المواصلة',
    OrderType : 'نوع الاوردر',
    Cages : 'البوبات',
    Current :'الحالي',
    Truck:'الشاحنه',
    Started : 'بدايه',
    Cancel:'الغاء'
    // Add more Translations as needed
  },
};



export default Translations;
