import React, { useState, useEffect } from 'react';
import LanguageSwitch from './LanguageSwitch';
import Translations from './Dictionary';

import LanguageObject from './languageObject';



const LanguageEncaps = ({setGlobalLang}) => {
  const [language, setLanguage] = useState(LanguageObject['Lang']);
  const changeLanguage = (lang) => {
    LanguageObject['Lang'] = lang
    setLanguage(lang);
    setGlobalLang(lang);
  };

  return (
    <LanguageSwitch changeLanguage={changeLanguage} language={language} />
  )
}  

export default LanguageEncaps;