// App.js
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate  } from 'react-router-dom';
import Form from './components/Form';
import SecurityForm from './components/SecurityForm';

import MessagePage from './components/MessagePage';
import LoginComponent from './components/LoginComponent';
import AuthHelper from './components/AuthHelper';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/reset';
import Register from './components/register';
import Header from './components/header';

import FormSubmitted from './components/FormSubmitted';

import LanguageObject from './components/languageObject';

import SupplierLanding from './components/SupplierLanding';
import '@fortawesome/fontawesome-free/css/all.css';
import './style_maf.css'
import './custom.scss'
import './customcss.css'
import Translations from './components/Dictionary';
import ConfirmEmail from './components/ConfirmEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailUnconfirmedPage from './components/EmailNotConfirmedPage';
import RecaptchaProtection from './components/RecaptchaProtection';

import Loader from './components/Loader';
import { registerLoader } from './components/LoaderController'; 


const Home = ({navigate, setUserData}) => {

  useEffect(() => {
    async function getUserDataAndLand()
    {
      let userInfo = await AuthHelper.GetUserData(navigate,setUserData);
      //console.log(userInfo)
      if(Object.keys(userInfo).length == 0)
      {
        //console.log(localStorage.getItem('refresh-Token'))
        //console.log(localStorage.getItem('access-Token'))

        //console.log('should nav')
        navigate('/login');
      }
      if (userInfo['role'] == 'Supplier')
      {
        navigate('/scheduled-orders');
      }
      else if(userInfo['role'] == 'Security')
      {
        navigate('/security');
      }
      
      
      setUserData(userInfo);
    }
    
    getUserDataAndLand();
    
    
  }, []);
  return(
  <div></div>)
};



function App() {
  const [userData, setUserData] = useState({});
  const [returnPage, setReturnPage] = useState('/login')
  const [ResultObj, setResultObj] = useState({})
  const [ConfirmEmailData, setConfirmEmailData] = useState({})
  const [ShowCaptcha, setShowCaptcha] = useState(false)
  const [ShowLoading, setShowLoading] = useState(false)

  const captchRef = useRef(null);

  const TriggerCaptcha = () => {
    if (captchRef.current) {
      captchRef.current.TriggerCaptchaCount();
    }
  };

  

  const navigateToPage = useNavigate();

  const UpdateTitle = (page) => {
    let titleString = new String(page);
    document.title = 'FastQ - '+ titleString.slice(1).replace('-', ' ');
  }
  const navigate = (page) => {
    UpdateTitle(page);
    navigateToPage(page)
  }

  const [language, setLanguage] = useState(LanguageObject['Lang']);
  const [SidePaneVisible, setSidePaneVisible] = useState(false);
  const [NavRef, setNavRef] = useState({})

  useEffect(() => {
    registerLoader(setShowLoading);
    //console.log('app refresh: ', userData)
    if(shallAppear())
    {
      setSidePaneVisible(true)
    } 
    else
    {
      setSidePaneVisible(false)
    }
  
  }, [userData]);

  const linksPaths = {
    'Supplier':[
      {'LinkName':Translations[language].scheduledOrders, 'LinkPath':'/scheduled-orders'}, 
      {'LinkName':Translations[language].bookOrder, 'LinkPath':'/book-order'}],
      'Security':[]
  }

  const shallAppear = () => {
    if (userData)
    {
      if (userData['role'])
      {
        if (linksPaths[userData['role']].length != 0) 
        {
          return true
        }
      }
      else
      {
        return false
      }
    }
    return false
  }

  
  const returnLinkArray = () => {
    let arr = []
    if (userData)
    {
      if (userData['role'])
      {
        if (linksPaths[userData['role']]) 
        {
          arr = linksPaths[userData['role']]
        }
      }
    }
    return arr
  }


  return (
    <div style={{backgroundColor: 'white', minHeight:'100vh', height:'100%', display:'flex', flexFlow:'column', flexGrow:'initial'}}>
        <Header redirectMethod={navigate} setUserData={setUserData} setLanguage={setLanguage} userData={userData} setNavRef={setNavRef}/>
        <ToastContainer limit={3}/>
        <Loader isLoading={ShowLoading}></Loader>
        <RecaptchaProtection ref={captchRef} language={language} setOuterComponentVar={setShowCaptcha}></RecaptchaProtection>
        {!ShowCaptcha &&
        <div className='container-app' style={{position:'relative'}}>
              <div className={SidePaneVisible ? 'side-pane-app' : 'side-pane-app zero'} style={{visibility:SidePaneVisible ? 'visible':'hidden', 
              color:'white', fontSize:'calc(0.5vw + 0.6rem)', backgroundColor: 'gray-900'
              }}>
                <div className='pane-top-spacing'></div>
                <ul style={{paddingLeft:SidePaneVisible ? '1rem' : '0rem', paddingRight:SidePaneVisible ? '1rem' : '0rem'}} >
                  {returnLinkArray().map(//userData['role']
                    link => <li style={{listStyleType:'none', margin:'3px', padding:'3px'}}>
                      <Link class="nav-link" to={link['LinkPath']} onClick={(e) => {UpdateTitle(link['LinkPath'])}}
                      >{link['LinkName']}</Link>
                      {/* <hr/> */}
                      </li>
                  )}
                </ul>
              </div>
              <div className='main-content-app'>
              <Routes>            
                  <Route path="/security" element={<SecurityForm userData={userData} navigate={navigate} 
                  setUserData={setUserData} setReturnPage={setReturnPage} setResultObj={setResultObj}
                  language={language}/>}>
                    
                  </Route>
                  <Route path="/book-order" element={<Form userData={userData} navigate={navigate} 
                  setUserData={setUserData} setReturnPage={setReturnPage} setResultObj={setResultObj}
                  language={language}/>}>
                    
                  </Route>

                  <Route path="/scheduled-orders" element={<SupplierLanding userData={userData} navigate={navigate} 
                  setUserData={setUserData} language={language}/>}>
                    
                  </Route>

                  <Route path="/" element={ <Home navigate={navigate} setUserData={setUserData}/>}>
                  
                  </Route>
                  <Route path="/get-time" element={ <MessagePage language={language}/>}>
                  
                  </Route>
                  <Route path="/login" element={ <LoginComponent redirectMethod={navigate} setUserData={setUserData} 
                  language={language} TriggerCaptcha={TriggerCaptcha}/>}>
                  
                  </Route>
                  <Route path="/forgot-password" element={ <ForgotPassword navigate={navigate} setUserData={setUserData} 
                  setConfirmEmailData={setConfirmEmailData} language={language} TriggerCaptcha={TriggerCaptcha}/>}>
                  
                  </Route>
                  
                  <Route path="/reset-password" element={ <ResetPassword navigate={navigate} setUserData={setUserData} language={language}/>}>
                  
                  </Route>
                  <Route path="/register-supplier" element={ <Register navigate={navigate} language={language}/>}>
                  
                  </Route>
                  
                  <Route path="/FormSubmitted" element={ <FormSubmitted navigate={navigate} returnPage={returnPage} 
                  SubmissionResult={ResultObj}/>}></Route>
                                    

                  <Route path="/ConfirmEmail" element = {<EmailUnconfirmedPage ConfirmEmailData={ConfirmEmailData} 
                  navigate={navigate} setConfirmEmailData={setConfirmEmailData} setUserData={setUserData}
                  TriggerCaptcha={TriggerCaptcha}/> }></Route> 

                  <Route path="account/confirmEmail" element={<ConfirmEmail/>}></Route>


                </Routes>
              </div>
        </div>}
        
       
      {/* </div> */}
    </div>
  );
}

export default App;




