import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';

import ReCAPTCHA from 'react-google-recaptcha';
import ReloadCounter from './reloadCounter'; 
import PanelTitle from './PanelTitle';



const MessagePage = ({language}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deliveryCode = queryParams.get('DeliveryCode');

  const [message, setMessage] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');
  const [DisplayReCaptcha, setDisplayReCaptcha] = useState(false)
  const [DisplaySubmitCaptcha, setDisplaySubmitCaptcha] = useState(false)

    const handleCaptchaChange = (value) => {
        setCaptchaToken(value);
        if (value != null)
        {
          setDisplaySubmitCaptcha(true)
        }
    };

    const handleCaptchaLoad = () => {
      setDisplaySubmitCaptcha(true)
    }
    const handleCaptchaError = () => {
      setDisplaySubmitCaptcha(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send the captchaToken along with your form data to the server
        if (captchaToken) {
            // If reCAPTCHA token exists, navigate to another page
            //console.log('reCAPTCHA token:', captchaToken);
            //nav(navPage);
            localStorage.setItem('reloadCountArray', JSON.stringify([]));
            setDisplayReCaptcha(false)

        } else {
            // reCAPTCHA not solved yet
            helperMethods.ToasterError('Please complete the reCAPTCHA challenge.');
        }
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // Validate captchaValue and submit the form if valid
    // };

    useEffect(() => {
        // Check reload count from localStorage when component mounts
        setDisplayReCaptcha(ReloadCounter(5,1))
        
    }, []);


  
  const dateMethod = (data) => {
    ////console.log(data)
    let time = new Date(data['WaitingTime'])
    setMessage(time.toLocaleTimeString())
    //message['WaitingTime']
  }
  const dateMethodshow = (date) => {
    ////console.log(data)
    let time = new Date(date);
    return time.toLocaleTimeString();
    //setMessage(time.toLocaleTimeString())
    //message['WaitingTime']
  }


  useEffect(() => {
    //console.log(deliveryCode)
    if (deliveryCode) {
      helperMethods.ApiCall(configs["BaseURL"]+configs["GetArrivalTime"] + `?DeliveryCode=${deliveryCode}`, 
      'GET', undefined, setMessage, helperMethods.DefaultAPIResult)      
    }
  }, [,deliveryCode]);

  return (
    <div>
      {DisplayReCaptcha ? (
        <div >
          <ReCAPTCHA style={{textAlign: 'center'}}
              sitekey="6Le8XfIpAAAAAEPirmS-nPZdy_VhOVssxvTSNPLr"
              onChange={handleCaptchaChange}
              // onErrored={handleCaptchaError} 
              // asyncScriptOnLoad={handleCaptchaLoad} 
          />
          {DisplaySubmitCaptcha && (<button className='btn btn-primary' onClick={handleSubmit}>{Translations[language]['submit']}</button>)}
        </div>
      ): (<div>
        {deliveryCode ? (
          <div className='border container' style={{textAlign: 'center'}}>
            <PanelTitle>{Translations[language]['Time']}</PanelTitle>
            <p>{Translations[language]['WaitingTime'] + dateMethodshow(message['WaitingTime'])}</p>
          </div>
        ) : (
          <div className='border container' style={{textAlign: 'center'}}>
          <p>{Translations[language]['IncorrectCode']}</p></div>
        )}
      </div>)}
    </div>
    
  );
};

export default MessagePage;
