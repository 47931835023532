// Form.js
import React, { useState, useEffect } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import EmailUnconfirmed from './EmailNotConfirmed';
import Table from './Table';
import PageTitle from './PageTitle';
import PanelTitle from './PanelTitle';
import ModalComponent from './ModalComponent';

// const ModalComponent = ({ show, handleClose, handleSave }) => (
//   <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!show}>
//     <div className="modal-dialog" role="document">
//       <div className="modal-content">
//         <div className="modal-header">
//           <h5 className="modal-title" id="exampleModalLabel">Make Confirmation</h5>
//           {/* <button type="button" className="close" onClick={handleClose} aria-label="Close">
//             <span aria-hidden="true">&times;</span>
//           </button> */}
//         </div>
//         <div className="modal-body">
//           Are You Sure to cancel Order ?
//         </div>
//         <div className="modal-footer">
//           <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
//           <button type="button" className="btn btn-primary" onClick={handleSave}>Submit</button>
//         </div>
//       </div>
//     </div>
//   </div>
// );


  


const SupplierLanding = ({userData, navigate, setUserData, language}) => {
    
    const AddOrderActions_SetOrders = (orders) =>{
        orders.map(order => {
            order['Action'] = JSON.stringify(order)
        });
        setOrdersList(orders);
        //console.log(orders)
      }

    // const getSelectedTableItem = (index, e) => {
        
    //     let trueItems = sortMethod(filteredList(tableItems)) 
    //     buttonActionMethod(trueItems[index], e)     
    // }
    
    // const presentTable = (list) => {
    //     let newList = JSON.parse(JSON.stringify(list))
    //     newList.map((obj, index) => {
    //         Object.entries(presentationMethods).map(([key, val], presindex) => {
    //             obj[key] = val(obj[key], index);
    //             })
    //             delete obj.OrderID //temporary bad fix solve later
    //          return obj;
    //     })
    //     return newList;       
    // }
    
    const PresenetationFilters = {
        "OrderID": (val, index) => {
          return index;
        },
        "Expected Entry Date": (val, index) => {
          let d = new Date(val);
          let e = d.toLocaleTimeString();
          let c = d.toLocaleDateString();
          //console.log(c)
          //console.log(val)
          return c;
        },
        "Expected Entry Time": (val, index) => {
          let d = new Date(val);
          let e = d.toLocaleTimeString();
          let c = d.toLocaleDateString();
          ////console.log(c)
          ////console.log(val)
          return e;
        },
        
        "Action": (val, index) => {
            return <button class="btn btn-danger" onClick={(e) => handleOrderClick(JSON.parse(val), e)}>{Translations[language].Cancel}</button>
        }
      }


    // const [filters, SetFiltersObj] = useState({});

    
    // const getSelectedTableItem = (index, e) => {
        
    //     let trueItems = sortMethod(filteredList(tableItems)) 
    //     buttonActionMethod(trueItems[index], e)     
    // }

    const presentTable = (list) => {
        let newList = JSON.parse(JSON.stringify(list))
        newList.map((obj, index) => {
            Object.entries(PresenetationFilters).map(([key, val], presindex) => {
                obj[key] = val(obj[key], index);
                })
                        // Customize keys for presentation
                  //obj["Expected Entry Time"] = obj["ExpectedEntryTime"];
                delete obj.OrderID //temporary bad fix solve later
                delete obj.BranchID
                delete obj.SupID
             return obj;
        })
        return newList;       
    }

    //Another Temp Bad Fix 
    //Originally this was just inside table params: Object.keys(tableItems[0] || {})
    const removeIDFromHeaders = (keys) => {
        let newkeys = keys.filter((key) => {
            if(key =='OrderID' || key =='BranchID' || key =='SupID')
            {
                return false
            }
            else{
                return true
            }
        })
        return newkeys
    }

    // const filteredList = (list) => {
    //     //console.log(list);
    //     //console.log(filters)
    //     let filteredList = list.filter(item => {
    //         for (let key in filters) {
    //             //console.log(key)
    //             if (!filters[key].includes(item[key])) {
    //                 return false; // Object does not meet the filter criteria
    //             }
    //         }
    //         return true; // Object meets all filter criteria
    //     })
    //     return filteredList;
    // };

  
  const [EmailConfirmed, setEmailConfirmed] = useState(userData['emailConfirmed']);
  const [OrdersList, setOrdersList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [OneOrder, SetOneOrder] = useState({});



  const handleBookOrderClick = (e) => {
    //Registeration Without Booking Commit 
    //helperMethods.ToasterError('This feature will be available soon')
    navigate('/book-order')
  }
  const handleClose = () => {
    // Handle any business logic before closing
    //helperMethods.ToasterError('close');
    setShowModal(false);
  };
  
  const handleSave = () => {
    // Handle any business logic before saving
      helperMethods.TokenApiCall(configs["BaseURL"]+configs["CancelSupplierOrder"] + "?supplierID=" + OneOrder['SupID']
    + "&OrderNumber=" + OneOrder['Order Number'] + "&branchID=" + OneOrder['BranchID']
    , 'POST', undefined, AddOrderActions_SetOrders, helperMethods.DefaultAPIResult, true, navigate, setUserData)
    //helperMethods.ToasterSuccess('Cancel Oreder Successfully');
    //console.log(OneOrder);
    setShowModal(false);
  };
  
  const handleOrderClick = (order, e) => {// + "?CountryID=" + selectedCountry
    // long supplierID, string OrderNumber, long branchID
    setShowModal(true);
    //console.log("The order is :"+order);
    SetOneOrder(order); 
    // const userConfirmed =  window.confirm('Are you sure you want to cancel this order?')
    // if(userConfirmed){
    //   //console.log("ok")
    //   helperMethods.TokenApiCall(configs["BaseURL"]+configs["CancelSupplierOrder"] + "?supplierID=" + order['SupID']
    // + "&OrderNumber=" + order['OrderNum'] + "&branchID=" + order['BranchID']
    // , 'DELETE', undefined, AddOrderActions_SetOrders, helperMethods.DefaultAPIResult, true, navigate, setUserData)
    // }
    // else{
    //   //console.log("cancel")
    // } 
  }


  useEffect(() => {
    if(userData['role'] == 'Supplier')
    {
      // Fetch countries from an API
      helperMethods.TokenApiCall(configs["BaseURL"]+
      configs["GetSupplierBookedOrders"] + "?SupplierNum=" + userData['supplierNum'], 'GET', 
      undefined, AddOrderActions_SetOrders, helperMethods.DefaultAPIResult, true, navigate, setUserData)
    }
    else if(Object.keys(userData).length === 0)
    {
      navigate('/');
    }
    else {
      navigate('/login');
    }
    //console.log(userData)

    
    
  }, []);

 
  


  return (
    <div>
      {(EmailConfirmed != "True") && <EmailUnconfirmed userData={userData} navigate={navigate} setUserData={setUserData}/>}
      {(EmailConfirmed === "True") && <div class="mx-auto mt-4"> 
      
      <PageTitle>{Translations[language].currentlyBookedOrders}</PageTitle>

    <div className="border m-1" style={{width: '100%'}} >
        
        <Table className="table table-hover" tableItems={presentTable(OrdersList)} TableTitle={Translations[language].BookedOrders}
            headers={removeIDFromHeaders(Object.keys(OrdersList[0] || {}))}></Table>
        
        {/* <div className='m-2'></div> */}

    </div>
    <div style={{textAlign: 'center'}}>
        <button className='btn btn-primary m-2'  onClick={(e) => handleBookOrderClick(e)}
        style={{textAlign: 'center'}}>{Translations[language].bookNewOrder}</button>
        <ModalComponent show={showModal} handleClose={handleClose} handleSave={handleSave} language={language} Msg={Translations[language].CancelOrder}/>
    </div>

   
    </div>
    }
    </div>
  );
};

export default SupplierLanding;
