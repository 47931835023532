import React, { useState } from 'react';
import LanguageSwitch from './LanguageSwitch';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import LanguageObject from './languageObject';
import { jwtDecode } from "jwt-decode";
import AuthHelper from './AuthHelper';
import { Link } from 'react-router-dom';
import ValidatePassword from './ValidatePassword';
import PanelTitle from './PanelTitle';
import PasswordInputValidation from './PasswordInputValidation';

const Register = ({navigate, language}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [supplierNumber, setSupplierNumber] = useState('');
  const [taxID, setTaxID] = useState('');

  
  // const handlePasswordChange = (passval) =>{
  //   setPassword(passval)
  // }
  const OnSuccessAPI= (data)=>{
    //console.log('successReg: ', data)
    const submissionData = {"email": username}
    //helperMethods.ToasterError('Your registered successfully, You will be redirected to login')
    AuthHelper.ResendConfirmationEmail(submissionData, navigate, helperMethods.DefaultAPIResult)
  }

  // const OnFailedAPI= (data)=>{
  //   //helperMethods.ToasterError('Failed To register')
  //   //helperMethods.ToasterError(data['error'])
  //   if (data['error'])
  //   {
  //     helperMethods.ToasterError(data['error'])
  //     return;
  //   }
  //   helperMethods.ToasterError(data)
  // }

  const handleRegister = async (e) => {
    e.preventDefault();
    if(!ValidatePassword.passwordValidator(password))
    {
      helperMethods.ToasterError('password not accepted');
      return;
    }
    if(!username){
      helperMethods.ToasterError("Email Is Required");
      return;
    }
    else if (!supplierNumber){
      helperMethods.ToasterError("supplier Number Is Required");
      return;
    }


    // Call API for user registration (use 'username', 'password', 'supplierNumber' states)

    const submissionData = {
        "firstName": "",
        "lastName": "",
        "emailAddress": username,
        "userName": username,
        "password": password,
        "supplierNumber": supplierNumber,
        "taxID": taxID === '' ? '' : taxID
    }
    //console.log(submissionData)
    helperMethods.ApiCall(configs["BaseURL"]+configs["RegisterSupplier"], 'POST', submissionData, 
    OnSuccessAPI, helperMethods.DefaultAPIResult)

  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleRegister} className="border p-4">
        <PanelTitle>{Translations[language].Register1}</PanelTitle>
        <div className="mb-3">
          <label htmlFor="_register_username" className="form-label">
            {Translations[language].email}
          </label>
          <input
            type="email"
            className="form-control"
            id="_register_username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="_register_password" className="form-label">
            <ul>
              <li>Passwords must be at least 6 characters</li>
              <li>Passwords must have at least one lowercase ('a'-'z')</li>
              <li>Passwords must have at least one uppercase ('A'-'Z')</li>
              <li>Passwords must have at least one digit ('0'-'9')</li>
              <li>Passwords must have at least one non alphanumeric character</li>
            </ul>
            Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="_register_password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ValidatePassword.ValidatePasswordMessage password={password}></ValidatePassword.ValidatePasswordMessage>
        </div> */}
        <PasswordInputValidation password={password} setPassField={setPassword} language={language}></PasswordInputValidation>
        <div className="mb-3">
          <label htmlFor="_supplier_number" className="form-label">
            {Translations[language].supplierNumber}
          </label>
          <input
            type="text"
            className="form-control"
            id="_supplier_number"
            value={supplierNumber}
            onChange={(e) => setSupplierNumber(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="_tax_id" className="form-label">
          {Translations[language].TaxId}
          </label>
          <input
            type="text"
            className="form-control"
            id="_tax_id"
            value={taxID}
            onChange={(e) => setTaxID(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {Translations[language].submit}
        </button>
      </form>
    </div>
  );
};

export default Register;
