import React, { useState, useEffect } from "react"; 

const passwordValidator = (pass) => {
    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,100}$/;
    var test = reg.test(pass);
    return test    
  }

const ValidatePasswordMessage = ({password}) => { 

	const [errorMessage, setErrorMessage] = useState('') 
    const [color, setColor] = useState('red') 

    useEffect(() => {
        validate(password);
      }, [,password]);
    
    

	const validate = (value) => { 
        if (value === '')
        {
            setErrorMessage('') 
            return
        }

		if (passwordValidator(value)) { 
			setErrorMessage('- Password Accepted') 
            setColor('green')
		} else { 
			setErrorMessage('- password requirements are not met') 
            setColor('red')
		} 
	} 

	return ( 
		<div style={{ 
			//marginLeft: '200px', 
		}}> 
			<pre> 
				{errorMessage === '' ? null : 
					<span style={{ 
						fontWeight: 'bold', 
						color: color, 
					}}>{errorMessage}</span>} 
			</pre> 
		</div> 
	); 
} 
const ValidatePassword = { ValidatePasswordMessage, passwordValidator };

export default ValidatePassword;
