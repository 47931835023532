// SecurityForm.js
import React, { useState, useEffect } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import AuthHelper from './AuthHelper';
import PageTitle from './PageTitle';
import PanelTitle from './PanelTitle';



const SecurityForm = ({userData, navigate, setUserData, setReturnPage, setResultObj, language}) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [numberOfCars, setNumberOfCars] = useState('0');
  const [numberOfPalettes, setNumberOfPalettes] = useState('0');
  const [carDropdownOptions, setCarDropdownOptions] = useState([]);
  const [carDropdownValue, setCarDropdownValue] = useState(['']);
  const [reservationCode, setreservationCode] = useState('');
  const [branch, setBranch] = useState(userData['branchID']);
  const [branches, setBranches] = useState(userData['branchID']);
  const [BranchNames, setBranchNames] = useState(null);
 

  
  const OnSuccessAPI = (data) => {
    //console.log(data)
    var message = 'your turn: ' + data['WaitingInLine'] + ', waiting time: ' + data['PreferredArrivaltime'];
    setResultObj(data);
    setReturnPage('/security');
    navigate('/FormSubmitted');
  };

  const OnFailedAPI = (data) => {
    //console.log(data)
    var message = data['error']
    helperMethods.ToasterError(message)
  };
  

  const changeBranch = (e) =>{
    const index = e.target.value;

    //console.log('branch data: ', index, branches, BranchNames, branch)

    let TrueBranchID = branches[index].toString();
    setBranch(TrueBranchID);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // if(numberOfPalettes == '0' || numberOfCars == '0')
    //   {
    //     helperMethods.ToasterError(Translations[language].TransportationReason)
    //     return
    //   }
       if (!orderNumber)
      {
          helperMethods.ToasterError(Translations[language].OrderReason)
          return
      }
      else if(!reservationCode)
      {
          helperMethods.ToasterError(Translations[language].ReservationReason)
          return
      }
      // else if(!carDropdownValue || carDropdownValue == 0)
      // {
      //   helperMethods.ToasterError(Translations[language].TransTypeReason)
      //   return
      // }
    // Handle form submission logic here
    const submissionData = {
      // "TruckNumber":numberOfCars,
      // "OrderTransporterType":carDropdownValue,
      // "PaletteNumber":numberOfPalettes,
      "OrderNumber":orderNumber,
      "ReservationCode": reservationCode,
      "Branch": branch
    }
    //apiCalls('POST', "https://localhost:44300/api/Supplier/BookDelivery", submissionData)
    helperMethods.TokenApiCall(configs["BaseURL"]+configs["UpdateOrderCheckin"], 'POST', submissionData, 
    OnSuccessAPI, OnFailedAPI, true, navigate, setUserData)

    
  };

  
  useEffect(() => {
    //console.log(userData);
    
    if(userData['role'] == 'Security' || userData['role'] == 'RecievingEmployee')
    {
      let TrueBranchID = branch;
      if (userData['branchID'].startsWith("["))
      {
        let BranchArray = JSON.parse(userData['branchID']);
        setBranches(BranchArray);
        TrueBranchID = BranchArray[0].toString();
        setBranch(TrueBranchID);
        let branchNames = userData['branchName'].split(',');
        setBranchNames(branchNames);
      }

      helperMethods.TokenApiCall(configs["BaseURL"]+configs["GetOrderTransporters"] + "?Countryid=" + userData['countryID'], 'GET', 
    undefined, setCarDropdownOptions, helperMethods.DefaultAPIResult, true, navigate, setUserData)
    }
    else if(Object.keys(userData).length === 0)
    {
      //console.log(userData)
      //userData==null || userData == undefined || userData == {}
      navigate('/');
    }
    else {
      //console.log(userData)
      navigate('/login');
    }
    
  
}, []) 
const handleChange = (event) => {
  const value = event.target.value;
  navigate(value);
};
 

  return (
    <div>
    <PageTitle>{Translations[language].SecurityTitle}</PageTitle>
    {userData['role'] == 'RecievingEmployee' &&
    <div>
      <strong>{Translations[language].Screen}</strong>
      <select className= "form-select mt-2 mb-2" onChange={handleChange}>
      <option value="/security">Security Screen</option>
      <option value="/recieve">Receiving Screen</option>
    </select>
    </div>

    }
    
    {BranchNames && <div>
        <label htmlFor="BranchSelection" className="form-label">
            <strong>{Translations[language].branch}</strong>
          </label>
        <select
          id="BranchSelection"
          className= "form-select"//"selectLang" 
          value={BranchNames[branches.indexOf(branch)]}
          onChange={changeBranch}
          // style={{border: 'none', backgroundColor: '#00000000', cursor: 'pointer', fontSize: 'calc(0.8vw + 0.4rem)'}}
          style = {{marginBottom: '0.6rem'}}
        >
          {BranchNames.map((val, index) => (
          <option value ={index} >
            {val}
            <hr></hr>
          </option>
          ))}
         
        </select></div>}
    <div className="mt-4">

      <form onSubmit={handleSubmit} className="border p-4">
      <PanelTitle>{Translations[language].SecurityPanel}</PanelTitle>

        <div className="mb-3">
          <label htmlFor="_security_ordernum" className="form-label">
            {Translations[language].orderNumber}
          </label>
          <input
            type="text"
            className="form-control"
            id="_security_ordernum"
            aria-describedby="ordernumHelp"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
          />
        </div>

        {/* <div className="mb-3">
          <label htmlFor="_security_numberofpalettes" className="form-label">
            {Translations[language].numberOfPalettes}
          </label>
          <input
            type="number"
            min="0"
            className="form-control"
            id="_security_numberofpalettes"
            aria-describedby="numberofpalettesHelp"
            value={numberOfPalettes}
            onChange={(e) => setNumberOfPalettes(e.target.value)}
          />
        </div> */}

        {/* <div className="mb-3">
          <label htmlFor="_security_numberofcars" className="form-label">
            {Translations[language].numberOfCars}
          </label>
          <input
            type="number"
            min="0"
            className="form-control"
            id="_security_numberofcars"
            aria-describedby="numberofcarsHelp"
            value={numberOfCars}
            onChange={(e) => setNumberOfCars(e.target.value)}
          />
        </div> */}

        {/* <div className="mb-3">
          <label htmlFor="_security_cardropdown" className="form-label">
            {Translations[language].deliveryMethodCarType}
          </label>
          <select
            className="form-select"
            id="_security_cardropdown"
            value={carDropdownValue}
            onChange={(e) => setCarDropdownValue(e.target.value)}
          >
            {Object.keys(carDropdownOptions).map((option, index) => (
              <option key={index} value={option}>
                {carDropdownOptions[option]}
              </option>
            ))}
          </select>
        </div> */}

        <div className="mb-3">
          <label htmlFor="_security_reservationCode" className="form-label">
            {Translations[language].reservationCode}
          </label>
          <input
            type="text"
            className="form-control"
            id="_security_reservationCode"
            aria-describedby="reservationCodeHelp"
            value={reservationCode}
            onChange={(e) => setreservationCode(e.target.value)}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          {Translations[language].submit}
        </button>
      </form>
    </div>
    </div>
  );
};

export default SecurityForm;
