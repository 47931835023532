import React, { useState } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import LanguageObject from './languageObject';
import { jwtDecode } from "jwt-decode";
import AuthHelper from './AuthHelper';
import { Link } from 'react-router-dom';
import PanelTitle from './PanelTitle';
import { useLocation } from 'react-router-dom';
import PasswordInputValidation from './PasswordInputValidation';
import ValidatePassword from './ValidatePassword';

const ResetPassword = ({navigate, setUserData, language}) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const Code = queryParams.get('code');


  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const[VaildTokenYN, setVaildTokenYN]  = useState(true);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const submissionData = {
      "email":email
    } 

    AuthHelper.ForgotPassword(submissionData, navigate, setUserData);
    navigate('/login')
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const submissionData = {
      "email":email,
      "resetCode": Code,
      "newPassword": newPassword
    } 
    if(!email){
      helperMethods.ToasterError('Email is Required')
      return;
    }
    else if(!ValidatePassword.passwordValidator(newPassword))
    {
      helperMethods.ToasterError('password not accepted');
      return;
    }
    // else if (!newPassword){
    //   helperMethods.ToasterError('Password is Required')
    //   return;
    // }

    const OnSuccessAPI = (response) =>{
      setVaildTokenYN(true)
      helperMethods.ToasterSuccess('Password Updated Successfully' , {
      theme: "colored",
      autoClose:false
      })
      navigate('/login');
 
    }
    const OnErrorAPI = (response) =>{
      setVaildTokenYN(false)
      helperMethods.ToasterError('The Validity of Link is 2 hours. You Can Resend another link' , {
        theme: "colored",
        autoClose:false
        })
    }
    //AuthHelper.ResetPassword(submissionData, navigate, setUserData);
    helperMethods.ApiCall(configs["BaseURL"]+configs["ResetPassword"], 'POST', submissionData, OnSuccessAPI,
    OnErrorAPI);
    // AuthHelper.ResetPassword(submissionData, navigate, setUserData);
    // helperMethods.ToasterSuccess('Password Updated Successfully' )
    // navigate('/login');
  };

  return (
    <div className="container mt-4">
      <form onSubmit={handleResetPassword} className="border p-4">
        <PanelTitle>{Translations[language].ResetPass}
        </PanelTitle>
        <div className="mb-3">
          <label htmlFor="_reset_email" className="form-label">
            {Translations[language].EnterMail}
          </label>
          <input
            type="email"
            className="form-control"
            id="_reset_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <PasswordInputValidation password={newPassword} setPassField={setNewPassword} language={language}></PasswordInputValidation>
       
        <div>
          <span>
          {VaildTokenYN &&<button type="submit" className="btn btn-primary">
            {Translations[language].ResetPass}
        </button>}
        </span>
        <span>
          <span className="p-2"></span>
        {!VaildTokenYN &&
        <button onClick={handleForgotPassword} className="btn btn-primary">
            {Translations[language].resendResetLink}
        </button>
        }
        </span>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
