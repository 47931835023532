import Translations from "./Dictionary";


const ModalComponent = ({ show, handleClose, handleSave , Msg , language }) => (
    <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!show}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{Translations[language].MakeConfirmation}</h5>
            {/* <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div className="modal-body">
          {Msg} 
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>{Translations[language].Close}</button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>{Translations[language].submit}</button>
          </div>
        </div>
      </div>
    </div>
  );

  export default ModalComponent;