import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Code = queryParams.get('code');
    const UserId = queryParams.get('userId');
    
    const navigate = useNavigate();
 
    const handleClick = () => {
      navigate('/login');
    };  

    const [Message, setMessage] = useState('');
    //const ChangeEmail = queryParams.get('DeliveryCode');
    // //console.log(Code+"  " + UserId)

    const OnSuccessAPI =() => {
      setMessage("Thanks");
    };
    const OnFailedAPI =() => {
      setMessage("Badrequest");
    };

    useEffect(() => {
          helperMethods.ApiCall(configs["BaseURL"]+configs["EmailConfigration"] + `?userId=${UserId}&code=${Code}`, 
          'GET', undefined, OnSuccessAPI,OnFailedAPI )     
          //console.log(configs["BaseURL"]+configs["EmailConfigration"] + `?userId=${UserId}&code=${Code}`)
      }, []);
      return (
        <div class="container text-center">
  <div class="row">
    <div class="col">
    </div>
    <div class="col">
      <h4> {Message}</h4>
      <button onClick={handleClick} className='btn btn-primary'>Back to Login</button>
    </div>
    <div class="col">
    </div>
  </div>
</div>
        //<div>You are confirmed your account successfully ,Thanks</div>
    );
};
export default ConfirmEmail;