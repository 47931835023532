// loaderController.js
let setLoaderState = null;

export const registerLoader = (setter) => {
  setLoaderState = setter;
};

export const showLoader = () => {
  if (setLoaderState) setLoaderState(true);
};

export const hideLoader = () => {
  if (setLoaderState) setLoaderState(false);
};
