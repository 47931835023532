// Login.js
import React, { useState, useEffect, useRef } from 'react';
import Translations from './Dictionary';
import 'bootstrap/dist/css/bootstrap.min.css';
import helperMethods from '../HelperMethods';
import configs from './Configs.json';
import LanguageObject from './languageObject';
import { jwtDecode } from "jwt-decode";
import AuthHelper from './AuthHelper';
import { Link } from 'react-router-dom';
import PanelTitle from './PanelTitle';
import RecaptchaProtection from './RecaptchaProtection';



const Login = ({redirectMethod, setUserData, language, TriggerCaptcha}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  
  
  const handleLogin = (e) => {
    // TriggerCaptcha();
    e.preventDefault();

    // Implement your login logic here

    // Handle form submission logic here
    const submissionData = {
        "emailAddress": email,
        "password": password
    }
    //console.log(submissionData)
    //console.log(redirectMethod)
    //console.log(setUserData)
    AuthHelper.Login(submissionData, redirectMethod, setUserData, TriggerCaptcha);
    // helperMethods.ApiCall(configs["BaseURL"]+configs["Login"], 'POST', submissionData, 
    // OnSuccessLogin, helperMethods.DefaultAPIResult)
  };

  return (
    <div>
      <div className="container mt-4">
     
        <form onSubmit={handleLogin} className="border p-4">
          <PanelTitle>{Translations[language].Login}</PanelTitle>
 
          <div className="mb-3">
            <label htmlFor="_login_email" className="form-label">
              {Translations[language].emailUserName}
            </label>
            {/* <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} /> */}
            <input
              type="text"
              className="form-control"
              id="_login_email"
              aria-describedby="loginemailhelp"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
 
       
 
          <div className="mb-3">
            <label htmlFor="_login_password" className="form-label">
              {Translations[language].password}
            </label>
            <input
              type="password"
              className="form-control"
              id="_login_password"
              aria-describedby="reservationCodeHelp"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {Translations[language].submit}
          </button>
        </form>
        {/* class="nav-link" */}
        <div><Link  to="/forgot-password">{Translations[language].ForgetPassword}</Link></div>
     
      <div><Link  to="/register-supplier">{Translations[language].Register}</Link></div>
       
 
      {/* <h2>Login</h2>
      <label>Email:</label>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <label>Password:</label>
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleLogin}>Login</button>
      <p>
        <a href="#" onClick={handleForgotPassword}>
          Forgot Password?
        </a>
      </p> */}
    </div>
    </div>
  );
};

export default Login;
