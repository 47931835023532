import React from 'react';

// Functional component example
const PanelTitle = (props) => {
  return (
    <div>
        <div style={{width: '100%', backgroundColor: 'rgb(181, 151, 90)', color:'white'}} className='p-2'>
            <strong> <h3>{props.children}</h3> </strong></div>
    </div>
  );
};

export default PanelTitle;
